$(function () {
  //   var nav = $(".headermenu");
  //   //navの位置
  //   var navTop = nav.offset().top + 100;
  //   var navH = -nav.outerHeight();
  //   //スクロールするたびに実行
  //   $(window).scroll(function () {
  //     var winTop = $(this).scrollTop();
  //     if (winTop > navTop) {
  //       nav.css({ top: navH });
  //       nav.addClass("fixed");
  //       nav.animate({ top: 0 }, 1000);
  //     } else if (winTop <= navTop) {
  //       nav.removeClass("fixed");
  //     }
  //   });

  $("#gmenu").click(function () {
    console.log("menu");
    $(this).toggleClass("active");
    $("#nav").toggleClass("panelactive");
    $("#nav-bg").toggleClass("panelactive");
  });
  $("#nav-close").click(function () {
    $("#gmenu").toggleClass("active");
    $("#nav").toggleClass("panelactive");
    $("#nav-bg").toggleClass("panelactive");
  });
  $("#g-nav-bg").click(function () {
    $("#gmenu").toggleClass("active");
    $("#nav").toggleClass("panelactive");
    $("#nav-bg").toggleClass("panelactive");
  });

  $(".news__btn-tab__list__item").click(function () {
    $('.news__btn-tab__list__item').removeClass('active');
    $('.news__btn-tab__panel').removeClass('active');
    $(this).toggleClass("active");
    
    var index = $('.news__btn-tab__list__item').index(this);
    $('.news__btn-tab__panel').eq(index).addClass('active');

    // if ($(this).text() === "詳細を見る ▼") {
    //   $(this).text("詳細を閉じる ▲");
    // } else {
    //   $(this).text("詳細を見る ▼");
    // }
  });

  $(window).scroll(function () {
    $(".fadein").each(function () {
      var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 200) {
        $(this).addClass("active");
      }
    });
  });
  
});

$(window).on("load", function () {});
